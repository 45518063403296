import PhoneInput from "react-phone-number-input/input";
import { useField } from "formik";

const PhoneInputField = ({ label, ...props }) => {
    const [field,, helpers] = useField(props);

    return (
        <PhoneInput
            {...field}
            {...props}
            value={field.value}
            defaultCountry="US"
            onChange={value => helpers.setValue(value)}
        />
    );
}

export default PhoneInputField;
