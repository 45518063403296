import * as Sentry from "@sentry/react";
import {Component} from "react";
import Header from "../components/Header";
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import PhoneInputField from "../components/PhoneInputField";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";

export default class InviteUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            organization: {},
            errorMessage: '',
            initialValues: {
                users: []
            }
        };

        this.inviteUsers = this.inviteUsers.bind(this);
    };

    async componentDidMount() {
        const inviteUuid = this.props.match.params.inviteUuid;
        const domain = this.props.match.params.domain;

        const getResponse = async () => {
            try {
                const response = await fetch(`/api/organizationLookup/${domain}/${inviteUuid}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if(response.ok) {
                    return response.json();
                } else {
                    if (response.status === 403) {
                        this.props.history.push('/noaccess');
                    } else if (response.status === 404) {
                        this.props.history.push('/invitenotfound');
                    } else {
                        Sentry.captureMessage(`Unexpected status code ${response.status} from /api/organizationLookup/`, 'error');
                        this.props.history.push('/error');
                    }
                }
            } catch (err) {
                Sentry.captureException(err);
                this.props.history.push('/error');
            }
        };

        const data = await getResponse();

        if(!data) {
            return;
        }

        const userFields = {
            users: [...Array(data.available_seats)].map(n => ({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                mfa_enabled: true
            }))
        };

        this.validationSchema = Yup.object().shape({
            users: Yup.array().of(
                Yup.object().shape({
                    first_name: Yup.string().when('email', {
                        is: value => !!value,
                        then: Yup.string().required("This is a required field.").max(40, "Max 40 characters")
                    }),
                    last_name: Yup.string().when('first_name', {
                        is: value => !!value,
                        then: Yup.string().required("This is a required field.").max(40, "Max 40 characters.")
                    }),
                    email: Yup.string().when('last_name', {
                        is: value => !!value,
                        then: Yup.string().email("This must be an e-mail address.").required("This is a required field.")
                    }),
                    phone: Yup.string().test('phone', "Please enter a valid phone number or leave this field blank.", value => {
                        if (!value) return true;
                        return isValidPhoneNumber(value);
                    }),
                }, [['first_name', 'last_name'], ['first_name', 'email'], ['last_name', 'email']])
            )
        })

        this.setState({loading: false, organization: data, initialValues: userFields});
    }

    async inviteUsers(values) {
        const inviteUuid = this.props.match.params.inviteUuid;
        const domain = this.props.match.params.domain;
        // If the phone is not set, set to empty string
        const users = values.users.filter(user => !!user.email).map(user => ({
            phone: user.phone ? user.phone : '',
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            mfa_enabled: user.mfa_enabled
        }));

        const userFields = {
            users: [...users, ...[...Array(this.state.organization.available_seats - users.length)].map(n => ({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                mfa_enabled: true
            }))]
        };
        this.setState({processing: true, initialValues: userFields});
        try {
            const response = await fetch(`/api/provisionUsers/${domain}/${inviteUuid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({users: users})
            });

            if (response.ok) {
                this.props.history.push('/complete');
            } else {
                const message = await response.text();

                Sentry.setContext('response', {
                    status: response.status,
                    error: message
                });

                if (response.status === 400) {
                    this.setState({processing: false, errorMessage: message});
                } else if (response.status === 403) {
                    this.props.history.push('/noaccess');
                } else if (response.status === 404) {
                    this.props.history.push('/invitenotfound');
                } else {
                    Sentry.captureMessage(`Unexpected status code ${response.status} from /api/provisionUsers/`, 'error');
                    this.props.history.push('/error');
                }
            }
        } catch (err) {
            Sentry.captureException(err);
            this.props.history.push('/error');
        }
    }

    render() {
        if(this.state.loading || this.state.processing) {
            let message = "Saving..." ? this.state.processing : "Loading...";
            return (
                <div className="invite">
                    <Header />
                    <main className="loading-container">
                        <div className="d-flex justify-content-center">
                            <Spinner message={message}/>
                        </div>
                    </main>
                </div>
            );
        }

        const users = this.state.organization.users;

        const sortedUserKeys = Object.keys(users).sort((emailA, emailB) => {
            const lastNameA = users[emailA].last_name.toLowerCase();
            const lastNameB = users[emailB].last_name.toLowerCase();

            if (lastNameA > lastNameB) return 1;
            if (lastNameA < lastNameB) return -1;
            return 0;
        });

        const existingUserRows = sortedUserKeys.map((e, i) => {
            const phoneNumber = parsePhoneNumber(users[e].phone, 'US');
            return (<div className="row invite-form-row" key={"existing"+i}>
                <div className="col">
                    <input type="text" className="form-control" value={users[e].first_name} disabled={true} />
                </div>
                <div className="col">
                    <input type="text" className="form-control" value={users[e].last_name} disabled={true} />
                </div>
                <div className="col">
                    <input type="text" className="form-control" value={e} disabled={true} />
                </div>
                <div className="col">
                    <PhoneInput defaultCountry="US" type="tel" className="form-control" value={phoneNumber ? phoneNumber.number : ""} disabled={true} onChange={() => {}} />
                </div>
                <div className="col mfa-box">
                    <input type="checkbox" className="form-control" checked={users[e].mfa_enabled} disabled={true} />
                </div>
            </div>)
        });

        const accountManager = this.state.organization.external_account_id.startsWith('ICA-') ? 'Intuit' : 'Summit Hosting';
        let instructions;
        if (this.state.organization.available_seats === 0) {
            instructions = (<div>
                <h2>You've invited all of your users.</h2>
                <h4>If you would like to purchase more user logins, please contact your {accountManager} account manager.</h4>
            </div>);
        } else if (this.state.errorMessage) {
            instructions = (<div>
                <h2>{this.state.errorMessage}</h2>
            </div>);
        } else {
            instructions = (<div>
                <h2>Please invite up to {this.state.organization.available_seats} users.</h2>
                <h4>You may come back to this site any time to invite more users.</h4>
                <h4>If you would like to purchase more user logins, please contact your {accountManager} account manager.</h4>
            </div>);
        }

        let mfaWarning = false;

        return (
            <div className="invite">
                <Header />
                <main className="container">
                    <div className="jumbotron jumbotron-fluid py-5 invite-jumbo">
                        <div className="container text-center">
                            {instructions}
                            <hr className="invite-form-header-spacer" />
                            <div className="row">
                                <div className="col text-left invite-form-header">First name</div>
                                <div className="col text-left invite-form-header">Last name</div>
                                <div className="col text-left invite-form-header">Email</div>
                                <div className="col text-left invite-form-header">Phone</div>
                                <div className="col text-left mfa-box"><span className="mfa-box-text">Use MFA</span></div>
                            </div>
                            <Formik initialValues={this.state.initialValues} validationSchema={this.validationSchema}
                                    onSubmit={this.inviteUsers} enableReinitialize={true}
                                    render={({values, errors}) => {
                                    // Check if any new user has MFA unchecked and details populated
                                    mfaWarning = values.users.some(user => user.email && !user.mfa_enabled);
                                    return <Form>
                                        {existingUserRows}
                                        <FieldArray name="users">
                                            {({ insert, remove, push }) => (
                                                <div>
                                                    {values.users.length > 0 && values.users.map((user, index) => (
                                                        <div className="row invite-form-row" key={index}>
                                                            <div className="col">
                                                                <Field name={`users.${index}.first_name`} type="text" className="form-control" placeholder="First Name"/>
                                                                <ErrorMessage name={`users.${index}.first_name`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col">
                                                                <Field name={`users.${index}.last_name`} type="text" className="form-control" placeholder="Last Name"/>
                                                                <ErrorMessage name={`users.${index}.last_name`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col">
                                                                <Field name={`users.${index}.email`} type="email" className="form-control" placeholder="email@example.com"/>
                                                                <ErrorMessage name={`users.${index}.email`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col">
                                                                <PhoneInputField name={`users.${index}.phone`} type="tel" className="form-control" placeholder="(555) 123-4567 (Optional)"/>
                                                                <ErrorMessage name={`users.${index}.phone`} component="div" className="field-error"/>
                                                            </div>
                                                            <div className="col mfa-box">
                                                                <Field name={`users.${index}.mfa_enabled`} type="checkbox" className="form-control"/>
                                                                <ErrorMessage name={`users.${index}.mfa_enabled`} component="div" className="field-error"/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                        {mfaWarning && (
                                            <div className="alert alert-warning mt-3">
                                                MFA is recommended to protect your user accounts.
                                            </div>
                                        )}
                                        <div className="form-group row invite-submit-row">
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary float-right" disabled={this.state.organization.available_seats === 0}>Submit</button>
                                            </div>
                                        </div>
                                    </Form>;
                                }}>
                            </Formik>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}
